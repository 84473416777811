<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <!-- <div class="mainList_operation_search">
            <div class="search_center">
              部门名称：
              <el-input
                placeholder="请输入部门名称"
                v-model="parameter.deptName"
                class="ipt_width expense__width"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              部门编号：
              <el-input
                placeholder="请输入部门编号"
                v-model="parameter.deptCode"
                class="ipt_width expense__width"
                clearable
              >
              </el-input>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div> -->
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            tooltip-effect="dark"
            class="multipleTable"
            :data="tableData"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :indent="25"
            :default-expand-all="false"
            :cell-class-name="treeSelectClass"
            @row-click="rowClick"
            v-loading="loading"
          >
            <el-table-column align="left" prop="deptName" label="部门名称" min-width="300">
              <template slot-scope="scope">
                <i
                  :class="`iconfont ${iconType(scope.row)}`"
                  :style="{ color: '#409EFF', 'margin-right': '5px' }"
                ></i>
                <span>{{ scope.row.deptName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="deptCode"
              align="center"
              label="部门编号"
              min-width="120"
            ></el-table-column>
            <!-- 
            <el-table-column
              prop="deptLeaderName"
              align="center"
              label="部门组长"
            ></el-table-column>
            <el-table-column
              prop="deptManagerName"
              align="center"
              label="部门经理"
            ></el-table-column>
            <el-table-column
              prop="deptSeniorManagerName"
              align="center"
              label="部门分管高管"
            ></el-table-column> -->

            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" size="small" class="text_Remove_Bgc" @click="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <EditDialog
      width="600px"
      :isShow.sync="showEditDialog"
      :loading.sync="editDialogLoading"
      @submit="submit"
    >
      <div slot="title" class="title">{{ form.id ? '编辑' : '新增' }}部门</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="form" :rules="rules" ref="form">
          <el-form-item label="父级菜单" prop="parentId" ref="parentId">
            <Treeselect
              :normalizer="normalizer"
              @input="changeParentId"
              placeholder="请选择父级菜单"
              v-model="form.parentId"
              :options="treeselectOptions"
            >
            </Treeselect>
          </el-form-item>
          <el-form-item label="部门名称" prop="deptName" ref="deptName">
            <el-input v-model="form.deptName" placeholder="请输入部门名称"></el-input>
          </el-form-item>

          <el-form-item label="部门编码" prop="deptCode" ref="deptCode">
            <el-input v-model="form.deptCode" placeholder="请输入部门编码"></el-input>
          </el-form-item>

          <!-- <el-form-item label="部门组长" prop="deptLeader" ref="deptLeader" v-if="form.isGroup">
            <ChoiceData
              style="margin-right: 15px"
              :loading="selectUserListLoading"
              :configure="{
                request: this.$api.user.listStaff,
                type: 'radio',
                params: {
                  deptId: form.id,
                },
              }"
              :projectManagerDlgVisible="userSelectDialogVisible"
              :deptCompetentManagerList="userList"
              @choiceDataChange="deptCompetentLeaderChange"
              @close="onDialogClose"
            >
              <el-input
                v-model="form.deptLeaderName"
                placeholder="请选择部门组长"
                @focus="getUserList"
                slot="button"
              ></el-input>
              <div class="mainList_operation clearfloat" slot="search">
                <div class="mainList_operation_search">
                  <el-input
                    class="item"
                    style="margin-right: 20px"
                    placeholder="部门"
                    readonly
                    v-model="deptCompetentManager.deptName"
                  ></el-input>
                  <el-input
                    class="item"
                    placeholder="姓名"
                    clearable
                    v-model="deptCompetentManager.userName"
                  ></el-input>
                  <el-button type="primary" plain icon="el-icon-search" @click="serachUserList"
                    >搜索</el-button
                  >
                </div>
              </div>
              <template slot="tableColumn-left">
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <el-table-column align="center" prop="postName" label="岗位"> </el-table-column>
              </template>
            </ChoiceData>
          </el-form-item>

          <el-form-item label="部门经理" prop="deptManager" ref="deptManager" v-if="form.isDept">
            <el-input
              v-model="form.deptManagerName"
              placeholder="请选择部门经理"
              @focus="getManagerList"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="部门分管高管"
            prop="deptSeniorManager"
            ref="deptSeniorManager"
            v-if="form.isDept"
          >
            <el-input
              v-model="form.deptSeniorManagerName"
              placeholder="请选择部门分管高管"
              @focus="getSeniorExecutiveList"
            ></el-input>
          </el-form-item> -->

          <el-form-item label="排序" prop="sort" ref="sort">
            <el-input v-model="form.sort" placeholder="请输入排序"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <!-- 多选成员 -->
    <!-- <el-dialog
      :visible.sync="checkBoxDialogVisable"
      v-if="checkBoxDialogVisable"
      :before-close="cancelSelect"
      append-to-body
      width="65%"
    >
      <div slot="title" style="color: #409eff">
        {{ checkBoxDialogType == 1 ? '选择部门经理' : '选择部门分管高管' }}
      </div>
      <div class="mainList_operation_search">
        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="部门"
          readonly
          v-model="checkBoxDialogParameter.deptName"
          v-if="checkBoxDialogType == 1"
        ></el-input>

        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="姓名"
          clearable
          v-model="checkBoxDialogParameter.userName"
        ></el-input>
        <el-button
          type="primary"
          plain
          icon="el-icon-search"
          @click="getCheckBoxDialogList(checkBoxDialogType)"
          >搜索</el-button
        >
      </div>
      <el-table
        v-loading="checkBoxDialogLoading"
        :data="checkBoxDialogList"
        style="width: 100%"
        max-height="400"
        border
        id="selectedTable"
        :row-key="getRowKey"
        @selection-change="handleCurrentChange"
        @row-click="handRowClick"
        :row-style="rowClass"
        ref="selectedTable"
      >
        <el-table-column type="selection" label="选择" :reserve-selection="true" width="60">
        </el-table-column>
        <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
        <el-table-column align="center" prop="postName" label="岗位"> </el-table-column>
      </el-table>
      <div style="float: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="checkBoxDialogPageChangeHandler"
          :current-page="parameter.pageNow"
          :page-size="parameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, prev, pager, next, slot, jumper"
          :total="parameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button type="success" style="min-width: 120px; margin: 0 25px" @click="saveSelectedFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancelSelect"
          >取 消</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'

export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Treeselect: () => import('@riophae/vue-treeselect'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
  },
  data() {
    return {
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,
      rules: {
        parentId: [
          {
            required: true,
            message: '请选择父级部门',
            trigger: ['blur', 'change'],
          },
          {
            trigger: ['change', 'blur'],
            validator: (rule, value, callback) => {
              if (this.form.id == value) {
                callback(new Error('父级部门不能为自身'))
              }
              callback()
            },
          },
        ],
        deptName: [
          {
            required: true,
            message: '请输入部门名称',
            trigger: ['blur', 'change'],
          },
        ],
        deptCode: [
          {
            required: true,
            message: '请输入部门编号',
            trigger: ['blur', 'change'],
          },
        ],
      },
      treeselectOptions: [],
      is_edit: false,
      parameter: {
        deptName: '',
        deptCode: '',
      },

      userList: [], //普通用户数组
      selectUserListLoading: false,
      userSelectDialogVisible: false,

      deptCompetentManager: {
        deptId: '',
        deptName: '',
        userName: '',
      },
      checkBoxDialogParameter: {
        deptId: '',
        deptName: '',
        userName: '',
        pageNow: 1,
        total: 0,
        pageSize: 50,
      },
      checkBoxDialogLoading: false,
      checkBoxDialogVisable: false,
      checkBoxDialogList: [],
      temporaryList: [],
      selectRow: [], //控制选中行变色
      arrSelect: [], //已选人员
      checkBoxDialogType: '',
      deptList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    iconType() {
      return function (row) {
        let result = ''
        switch (row.level) {
          case 1:
            result = 'iconcompany'
            break
          // case 2:
          //   result = 'iconcangku'
          //   break
          case 2:
            result = 'iconconnections'
            break
          case 3:
            result = 'iconCustomermanagement'
            break
        }
        return result
      }
    },
  },
  watch: {
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.selectRow = []
        if (val.length > 0) {
          this.selectRow = val.map(v => v.id)
        }
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.sysDept
      .listDept()
      .then(res => {
        this.deptList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    add() {
      this.form = {
        parentId: 0,
      }
      this.showEditDialog = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    edit(row) {
      this.form = Object.assign({}, row)
      // if (row.level == 3) {
      //   //选择的是level=3的部门，不存在组长，但存在部门经理和分管高管
      //   this.form.isGroup = false
      //   this.form.isDept = true
      // } else if (row.level == 4) {
      //   //选择的是level=3的小组，存在组长，不存在部门经理和分管高管
      //   this.form.isGroup = true
      //   this.form.isDept = false
      // } else {
      //   //选择的是level=1的总公司与level=2的子公司不存在组长、部门经理和分管高管
      //   this.form.isGroup = false
      //   this.form.isDept = false
      // }
      this.showEditDialog = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    normalizer(node) {
      //去掉children=null的属性
      if (node.children == null || node.children == 'null') {
        delete node.children
      }
      return {
        id: node.id,
        label: node.deptName,
        children: node.children,
      }
    },
    changeParentId() {
      this.$refs.form.validateField('parentId')
    },
    submit() {
      if (this.form.parentId == 0) {
        this.form.level = 1
      } else {
        const item = this.deptList.find(v => v.id == this.form.parentId)
        if (item) {
          this.form.level = item.level + 1
        }
      }

      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.editDialogLoading = true

          if (this.form.id) {
            // if (
            //   this.form.deptSeniorManagerList &&
            //   this.form.deptSeniorManagerList.length &&
            //   Array.isArray(this.form.deptSeniorManagerList)
            // ) {
            //   this.form.deptSeniorManager = this.form.deptSeniorManagerList.join(',')
            // }
            // if (
            //   this.form.deptManagerList &&
            //   this.form.deptManagerList.length &&
            //   Array.isArray(this.form.deptManagerList)
            // ) {
            //   this.form.deptManager = this.form.deptManagerList.join(',')
            // }
            this.$api.sysDept
              .edit(Object.assign(this.form))
              .then(res => {
                this.editDialogLoading = false
                this.showEditDialog = false
                this.$message.success('操作成功！')
                this.getData()
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          } else {
            this.$api.sysDept
              .add(Object.assign(this.form))
              .then(res => {
                this.editDialogLoading = false
                this.showEditDialog = false
                this.$message.success('操作成功！')
                this.getData()
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          }
        } else {
          this.scrollView(obj)
        }
      })
    },
    getData() {
      this.loading = true
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.loading = false
          this.tableData = res.data || []
          this.treeselectOptions = [
            {
              id: 0,
              deptName: '顶级部门',
              children: this.tableData,
            },
          ]
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.sysDept
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },

    pageChangeHandler() {
      this.loading = true
      this.$api.sysDept
        .listDept(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data || []
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    handleReset() {
      this.parameter.deptCode = null
      this.parameter.deptName = null
      this.getData()
    },

    // 点击第一列展开（防止行后有按钮，点击按钮造成展开）
    rowClick(row, column, e) {
      if (column?.label == '部门名称') {
        if (e.currentTarget.querySelector('.el-table__expand-icon')) {
          e.currentTarget.querySelector('.el-table__expand-icon').click()
        }
      }
    },

    treeSelectClass({ columnIndex }) {
      if (columnIndex == 0) {
        return 'selectCell'
      }
    },

    //选择部门组长
    deptCompetentLeaderChange(res) {
      this.form.deptLeaderName = res.userName
      this.form.deptLeader = res.id
      this.onDialogClose()
      this.$forceUpdate()
    },

    onDialogClose(val) {
      this.userSelectDialogVisible = val
      this.deptCompetentManager = {
        deptName: '',
        deptId: '',
        userName: '',
      }
    },

    getUserList() {
      this.userList = []
      this.selectUserListLoading = true
      this.deptCompetentManager.deptName = this.form.deptName
      this.$api.user
        .listStaff({
          pageSize: 1000000,
          deptId: this.form.id,
        })
        .then(res => {
          if (res.data.records) {
            this.userList = res.data.records
            const leaderIndex = this.userList.findIndex(item => item.id == this.form.deptLeader)
            if (leaderIndex !== -1) {
              let deleteArr = this.userList.splice(leaderIndex, 1)
              deleteArr[0].Selected = 1
              this.userList.unshift(deleteArr[0])
            }
            this.selectUserListLoading = false
            this.$forceUpdate()
          }
        })

      this.userSelectDialogVisible = true
    },

    serachUserList() {
      this.userList = []
      this.selectUserListLoading = true
      this.$api.user
        .listStaff({
          pageSize: 1000000,
          deptId: this.form.id,
          userName: this.deptCompetentManager.userName,
        })
        .then(res => {
          if (res.data.records) {
            this.userList = res.data.records
            const leaderIndex = this.userList.findIndex(item => item.id == this.form.deptLeader)
            if (leaderIndex !== -1) {
              let deleteArr = this.userList.splice(leaderIndex, 1)
              deleteArr[0].Selected = 1
              this.userList.unshift(deleteArr[0])
            }
            this.selectUserListLoading = false
            this.$forceUpdate()
          }
        })
    },

    getRowKey(row) {
      return row.id
    },
    handleCurrentChange(val) {
      this.temporaryList = val
    },
    // 对所选行进行样式设置
    rowClass({ row, rowIndex }) {
      if (this.selectRow.includes(row.id)) {
        return { 'background-color': '#ede88c' }
      }
    },
    // 点击行选中
    handRowClick(row) {
      this.$refs.selectedTable.toggleRowSelection(row)
    },

    checkBoxDialogPageChangeHandler(val) {
      this.checkBoxDialogParameter.pageNow = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.checkBoxDialogParameter.pageSize = val
      this.checkBoxDialogParameter.pageNow = 1
      this.getSelectedList()
    },

    getManagerList() {
      this.checkBoxDialogVisable = true
      this.arrSelect = []
      if (this.form.deptManagerList && this.form.deptManagerList.length > 0) {
        this.arrSelect = this.form.deptManagerList
      }
      this.checkBoxDialogType = 1
      this.checkBoxDialogParameter.userName = null
      this.checkBoxDialogParameter.deptId = this.form.id
      this.checkBoxDialogParameter.deptName = this.form.deptName
      this.checkBoxDialogParameter.pageNow = 1
      this.getCheckBoxDialogList(1) //打开的是部门经理选择框，用部门所有成员接口
    },

    getSeniorExecutiveList() {
      this.checkBoxDialogVisable = true
      this.arrSelect = []
      if (this.form.deptSeniorManagerList && this.form.deptSeniorManagerList.length > 0) {
        this.arrSelect = this.form.deptSeniorManagerList
      }
      this.checkBoxDialogType = 2
      this.checkBoxDialogParameter.userName = null
      this.checkBoxDialogParameter.deptId = this.form.id
      this.checkBoxDialogParameter.deptName = this.form.deptName
      this.checkBoxDialogParameter.pageNow = 1
      this.getCheckBoxDialogList(2) //打开的是部门经理选择框，用部门所有成员接口
    },

    getCheckBoxDialogList(type) {
      this.checkBoxDialogLoading = true
      if (type == 1) {
        this.$api.user
          .listStaff(this.checkBoxDialogParameter)
          .then(res => {
            this.checkBoxDialogList = res.data?.records.deepClone()
            this.checkBoxDialogParameter.total = res.data?.total
            this.$nextTick(() => {
              this.checkBoxDialogList.forEach(v => {
                if (this.arrSelect.indexOf(v.id) != -1) {
                  this.$refs.selectedTable.toggleRowSelection(v, true)
                } else {
                  this.$refs.selectedTable.toggleRowSelection(v, false)
                }
              })
            })
            this.checkBoxDialogLoading = false
          })
          .catch(err => {
            console.log(err)
            this.checkBoxDialogLoading = false
          })
      } else if (type == 2) {
        this.checkBoxDialogParameter.deptId = ''
        this.checkBoxDialogParameter.deptName = ''
        this.$api.project
          .listDepartmentHeadStaff(this.checkBoxDialogParameter)
          .then(res => {
            this.checkBoxDialogList = res.data?.records.deepClone()
            this.checkBoxDialogParameter.total = res.data?.total
            this.$nextTick(() => {
              this.checkBoxDialogList.forEach(v => {
                if (this.arrSelect.indexOf(v.id) != -1) {
                  this.$refs.selectedTable.toggleRowSelection(v, true)
                } else {
                  this.$refs.selectedTable.toggleRowSelection(v, false)
                }
              })
            })
            this.checkBoxDialogLoading = false
          })
          .catch(err => {
            console.log(err)
            this.checkBoxDialogLoading = false
          })
      }
    },

    cancelSelect() {
      this.checkBoxDialogVisable = false
      this.checkBoxDialogType = ''
      this.checkBoxDialogParameter = {
        pageNow: 1,
        pageSize: 50,
        userName: '',
        deptId: '',
        deptName: '',
      }
      this.temporaryList = []
    },

    saveSelectedFn() {
      if (this.checkBoxDialogType == 1) {
        // 更新到部门经理
        this.form.deptManagerList = this.form.deptManagerList || []
        this.form.deptManagerName = this.form.deptManagerName || ''
        this.form.deptManagerList = this.temporaryList.map(item => item.id)
        this.form.deptManagerName = this.temporaryList.map(item => item.userName).join(',')
        this.$forceUpdate()
      } else if (this.checkBoxDialogType == 2) {
        // 更新到部门分管高管
        this.form.deptSeniorManagerList = this.form.deptSeniorManagerList || []
        this.form.deptSeniorManagerName = this.form.deptSeniorManagerName || ''
        this.form.deptSeniorManagerList = this.temporaryList.map(item => item.id)
        this.form.deptSeniorManagerName = this.temporaryList.map(item => item.userName).join(',')
        this.$forceUpdate()
      }
      this.checkBoxDialogVisable = false
      this.temporaryList = []
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.selectCell {
  cursor: pointer;
}
// el-table表格对齐
/deep/ .el-table__row:not([class*='el-table__row--level-']) {
  td:first-child {
    padding-left: 24px !important; //一级数据无Child缩进
  }
}
/deep/ .el-table__placeholder {
  margin-left: 3px; //子节点无Child缩进
}

/deep/#selectedTable {
  .tableRowClassName {
    background-color: #ede88c;
  }

  tbody tr:hover > td {
    background-color: unset !important; //修改成自己想要的颜色即可
  }
}

.multipleTable {
  overflow: auto;
}
</style>
